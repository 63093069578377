











































































































import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {Component, Prop, Vue} from 'vue-property-decorator';
import DxLinkWrap from 'src/Component/Common/LinkWrap/LinkWrap.vue';
import {isEmpty, stopBodyScrolling, validateEmail} from '@labor-digital/helferlein';
import {MailService} from 'src/Service/MailService';
import {AppStoreKeys} from 'src/AppStoreKeys';

@Component({
    name: 'dx-contact',
    components: {
        DxLinkWrap
    }
})
export default class Contact extends Vue {
    @Prop() readonly context: ContentElementContext;
    @Prop({default: true}) readonly animate: boolean;
    public salute = '';
    public firstname = '';
    public lastname = '';
    public email = '';
    public phone = '';
    public company = '';
    public message = '';
    public sendError = '';
    public receiverEmailToken = '';
    public receiverEmail = '';
    public redirectLink = '';
    public isSending = false;
    public firstSending = false;
    public formSent = false;
    public policyAccepted = false;
    t;

    _contentElement: any;

    get contentElement() {
        return this._contentElement;
    }

    get classes() {
        let classes = '';

        if (this._contentElement && this._contentElement.spacer) {
            classes = this._contentElement.spacer.margin + ' ' + this._contentElement.spacer.padding;
        }

        if (this._contentElement.background) {
            classes += ' ' + this._contentElement.background;
        }

        return classes;
    }

    get firstnameError(): string {
        return !this.firstSending ? '' : this.validateField(this.firstname);
    }

    get lastnameError(): string {
        return !this.firstSending ? '' : this.validateField(this.lastname);
    }

    get mailError(): string {
        return !this.firstSending ? '' : !validateEmail(this.email) ? this.$t('contact.form.errorMail') : '';
    }

    get companyError(): string {
        return !this.firstSending ? '' : this.validateField(this.company);
    }

    get messageError(): string {
        return !this.firstSending ? '' : this.validateField(this.message);
    }

    get privacyLink(): string {
        let privacyPath = '';
        const link = this.context.pageContext.linkRepository.get('privacy').split('/');
        link.forEach((value, index) => {
            if (index > 2) {
                privacyPath += value;
                if (index + 1 < link.length) {
                    privacyPath += '/';
                }
            }
        });

        return privacyPath;
    }

    created() {
        // console.log('[Contact] _contentElement' , this.context.data.getAll());

        // @ts-ignore
        this._contentElement = this.context.data.getAll();

        this.receiverEmailToken = this._contentElement.receiverEmailToken;
        this.receiverEmail = this._contentElement.receiverEmail;
        // this.redirectLink = this._contentElement.redirectLink;

        const split = this._contentElement.redirectLink.split('/');
        split.forEach((value, index) => {
            if (index > 2) {
                this.redirectLink += value;
                if (index + 1 < split.length) {
                    this.redirectLink += '/';
                }
            }
        });

        console.log('this.redirectLink', this.redirectLink);
    };

    send() {
        this.isSending = true;
        this.firstSending = true;
        this.sendError = '';

        if (!isEmpty(this.firstnameError) || !isEmpty(this.lastnameError) || !isEmpty(this.mailError) || !isEmpty(this.companyError) || !isEmpty(this.messageError)) {
            this.isSending = false;
            return;
        }

        const data = {
            salute: this.salute,
            firstname: this.firstname,
            lastname: this.lastname,
            phone: this.phone,
            email: this.email,
            company: this.company,
            message: this.message,
            receiverEmail: this.receiverEmail,
            receiverEmailToken: this.receiverEmailToken,
            context: 'contact',
            origin: 'companies',
        };

        console.log('[ContactForm] data', data);

        stopBodyScrolling(false);
        this.context.store.set(AppStoreKeys.APP_LOADER_SHOW, true);

        MailService.send(data).then(
                (res) => {
                    if (res) {
                        this.isSending = false;
                        // this.formSent = true;

                        // - loader will be disabled through page change
                        // this.context.store.set(AppStoreKeys.APP_LOADER_SHOW, false);

                        this.$router.push({path: '/' + this.redirectLink});

                        return true;
                    }
                    throw new Error();
                },
                (err) => {

                    this.context.store.set(AppStoreKeys.APP_LOADER_SHOW, false);

                    this.isSending = false;
                    // @ts-ignore
                    this.sendError = this.$t('contact.form.sendError');
                    console.error('[ContactForm] error', err);
                    console.error('[ContactForm] error', err.message);
                }
        );

        return;
    }

    validateField(v): string {
        // @ts-ignore
        return isEmpty(v) ? this.$t('contact.form.error') : '';
    }
};
